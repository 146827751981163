// import Cookies from "universal-cookie";
// import { useState, useRef, useMemo } from "react";
// import { useSelector } from "react-redux";
// import { LoadingModal } from "./LoadingModal.jsx";
// import { ConfirmationModal } from "./ConfirmationModal.jsx";
// import { isValidAaaRefreshFile } from "../utils/scripts/inputValidations.js";
// import { PTPException } from "../utils/models/ptpException.js";
// import simpleFormStyles from "../styles/simple-form.module.css";
// import { Button } from "@mui/material";

// export default function AAAFileRefresh() {
//     const cookies = useMemo(() => new Cookies(), []);
//     const programName = useSelector((state) => state.program.name);
//     const [file, setFile] = useState(null);
//     const inputFileRef = useRef();
//     const [uploadLoading, setUploadLoading] = useState(false);
//     const [confirmationModalActive, setConfirmationModalActive] = useState(false);

//     const uploadToS3 = async (e) => {
//         try {
//             setUploadLoading(() => true);

//             const aaaRefreshFileUploadErrorCheck = isValidAaaRefreshFile(file, programName);
//             if (aaaRefreshFileUploadErrorCheck) throw new PTPException(aaaRefreshFileUploadErrorCheck);

//             const urlResponse = await fetch(
//                 `${process.env.REACT_APP_API_BASE_URL}/aaa-s3-upload-refresh-program-file?filename=${file.name}&program=${programName}`,
//                 {
//                     method: "GET",
//                     redirect: "follow",
//                     headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
//                 }
//             );

//             const urlData = await urlResponse.json();

//             if (!urlResponse.ok) {
//                 if (urlResponse.status === 400) throw new PTPException(urlData.message);
//                 else if (urlResponse.status === 500) throw new PTPException("File could not be uploaded at this time1");
//             }

//             const uploadUrl = urlData.url;

//             const uploadResponse = await fetch(uploadUrl, {
//                 method: "PUT",
//                 body: file,
//             });
//             if (!uploadResponse.ok) throw new PTPException("File could not be uploaded at this time2");

//             const refreshResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-refresh-program-file`, {
//                 body: JSON.stringify({
//                     filename: file.name,
//                     program: programName,
//                 }),
//                 method: "POST",
//                 headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
//             });
//             console.log(refreshResponse);
//             if (!refreshResponse.ok) throw new PTPException("File could not be uploaded at this time");

//             displayConfirmationModal();
//         } catch (err) {
//             console.log(err);
//             alert(err.name === "PTPException" ? err.message : "File could not be uploaded at this time4");
//         } finally {
//             inputFileRef.current.value = "";
//             setFile(() => null);
//             setUploadLoading(() => false);
//         }
//     };

//     const handleTruePaid = async (e) => {
//         const truePaidResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-refresh-true-paid-process-batch`, {
//             // body: JSON.stringify({
//             // 	filename: file.name,
//             // 	program: programName,
//             // }),
//             method: "POST",
//             headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
//         });
//         if (!truePaidResponse.ok) throw new PTPException("FAILURE");
//     };

//     const displayConfirmationModal = () => {
//         setConfirmationModalActive(() => true);

//         setTimeout(() => {
//             setConfirmationModalActive(() => false);
//         }, 2500);
//     };

//     return (
//         <div className="centered-container">
//             {uploadLoading === true && <LoadingModal text="Uploading file..." />}
//             {confirmationModalActive && <ConfirmationModal text="File successfully refreshed"></ConfirmationModal>}
//             <div className="program">
//                 <form className={simpleFormStyles.simpleForm}>
//                     <div className={simpleFormStyles.simpleFormInputContainer}>
//                         <label className={simpleFormStyles.simpleFormLabel}>File:</label>
//                         <input
//                             className={simpleFormStyles.simpleFormFileInput}
//                             type="file"
//                             name="file"
//                             accept=".txt,.csv,.xlsx"
//                             onChange={(e) => {
//                                 setFile(() => e.target.files[0]);
//                             }}
//                             ref={inputFileRef}
//                         ></input>
//                     </div>
//                     <div className={simpleFormStyles.simpleFormSubmitContainer}>
//                         <button type="button" className="run" onClick={uploadToS3}>
//                             Upload
//                         </button>
//                     </div>
//                     {/* True Paid Refresh Button just in case automation doesn't work */}
//                     {/* <Button variant="contained" onClick={handleTruePaid}>
//                         True Paid Batch Button
//                     </Button> */}
//                 </form>
//             </div>
//         </div>
//     );
// }

//Testing Auvelity
import Cookies from "universal-cookie";
import { useState, useRef, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { LoadingModal } from "./LoadingModal.jsx";
import { ConfirmationModal } from "./ConfirmationModal.jsx";
import { isValidAaaRefreshFile } from "../utils/scripts/inputValidations.js";
import { PTPException } from "../utils/models/ptpException.js";
import simpleFormStyles from "../styles/simple-form.module.css";
import { Button } from "@mui/material";

export default function AAAFileRefresh() {
    const cookies = useMemo(() => new Cookies(), []);
    const programName = useSelector((state) => state.program.name);
    const [file, setFile] = useState(null);
    const inputFileRef = useRef();
    const [uploadLoading, setUploadLoading] = useState(false);
    const [confirmationModalActive, setConfirmationModalActive] = useState(false);
    const [files, setFiles] = useState([]);

    //Checks if the files uploaded have the required key words
    const requiredAxsomeFiles = {
        file1: "PHARMACY",
        file2: "PATIENT",
    };

    const requiredXareltoFiles = {
        file1: "CLAIMS",
        file2: "ENROLLMENT",
        file3: "Testing",
    };

    const uploadAxsomeToS3 = async (e) => {
        try {
            setUploadLoading(() => true);

            const fileNames = files.map((file) => file.name);

            console.log(files);

            //File Length Check
            if (files.length !== requiredAxsomeFiles.length && files.length === 0)
                throw new PTPException("More than one required file is missing for this program.");
            else if (files.length !== requiredAxsomeFiles.length && files.length === 1)
                throw new PTPException("One required file is missing for this program.");
            else if (files.length > requiredAxsomeFiles.length) throw new PTPException("Too many files have been added than required. Refresh and try again.");

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const urlAxsomeResponse = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/aaa-s3-upload-refresh-program-file?filename=${file.name}&program=${programName}`,
                    {
                        method: "GET",
                        redirect: "follow",
                        headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
                    }
                );

                const urlData = await urlAxsomeResponse.json();
                const uploadAxsomeUrl = urlData.url;
                console.log(uploadAxsomeUrl);

                if (!urlAxsomeResponse.ok) {
                    if (urlAxsomeResponse.status === 400) throw new PTPException(urlData.message);
                    else if (urlAxsomeResponse.status === 500) throw new PTPException("File could not be uploaded at this time1");
                }

                const uploadAxsomeResponse = await fetch(uploadAxsomeUrl, {
                    method: "PUT",
                    body: file,
                });
                if (!uploadAxsomeResponse.ok) throw new PTPException("Axsome files could not be uploaded at this time2");
            }

            const refreshResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-auvelityRefresh-file`, {
                body: JSON.stringify({
                    filename: fileNames,
                    program: programName,
                }),
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
            });
            console.log(refreshResponse);
            if (!refreshResponse.ok) throw new PTPException("File could not be uploaded at this time");

            displayConfirmationModal();
        } catch (err) {
            console.log(err);
            alert(err.name === "PTPException" ? err.message : "Auvelity files could not be uploaded at this time4");
        } finally {
            inputFileRef.current.value = "";
            setFiles([]);
            setUploadLoading(() => false);
        }
    };

    const uploadXareltoToS3 = async (e) => {
        try {
            setUploadLoading(() => true);

            const fileNames = files.map((file) => file.name);

            console.log(files);

            //File Length Check
            if (files.length !== requiredXareltoFiles.length && files.length === 0)
                throw new PTPException("More than one required file is missing for this program.");
            else if (files.length !== requiredXareltoFiles.length && files.length === 1)
                throw new PTPException("One required file is missing for this program.");
            else if (files.length > requiredXareltoFiles.length) throw new PTPException("Too many files have been added than required. Refresh and try again.");

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const urlAxsomeResponse = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/aaa-s3-upload-refresh-program-file?filename=${file.name}&program=${programName}`,
                    {
                        method: "GET",
                        redirect: "follow",
                        headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
                    }
                );

                const urlData = await urlAxsomeResponse.json();
                const uploadAxsomeUrl = urlData.url;
                console.log(uploadAxsomeUrl);

                if (!urlAxsomeResponse.ok) {
                    if (urlAxsomeResponse.status === 400) throw new PTPException(urlData.message);
                    else if (urlAxsomeResponse.status === 500) throw new PTPException("File could not be uploaded at this time1");
                }

                const uploadAxsomeResponse = await fetch(uploadAxsomeUrl, {
                    method: "PUT",
                    body: file,
                });
                if (!uploadAxsomeResponse.ok) throw new PTPException("Axsome files could not be uploaded at this time2");
            }

            const refreshResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-auvelityRefresh-file`, {
                body: JSON.stringify({
                    filename: fileNames,
                    program: programName,
                }),
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
            });
            console.log(refreshResponse);
            if (!refreshResponse.ok) throw new PTPException("File could not be uploaded at this time");

            displayConfirmationModal();
        } catch (err) {
            console.log(err);
            alert(err.name === "PTPException" ? err.message : "Auvelity files could not be uploaded at this time4");
        } finally {
            inputFileRef.current.value = "";
            setFiles([]);
            setUploadLoading(() => false);
        }
    };

    const uploadToS3 = async (e) => {
        try {
            setUploadLoading(() => true);

            const aaaRefreshFileUploadErrorCheck = isValidAaaRefreshFile(file, programName);
            if (aaaRefreshFileUploadErrorCheck) throw new PTPException(aaaRefreshFileUploadErrorCheck);

            const urlResponse = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/aaa-s3-upload-refresh-program-file?filename=${file.name}&program=${programName}`,
                {
                    method: "GET",
                    redirect: "follow",
                    headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
                }
            );

            const urlData = await urlResponse.json();

            if (!urlResponse.ok) {
                if (urlResponse.status === 400) throw new PTPException(urlData.message);
                else if (urlResponse.status === 500) throw new PTPException("File could not be uploaded at this time1");
            }

            const uploadUrl = urlData.url;

            const uploadResponse = await fetch(uploadUrl, {
                method: "PUT",
                body: file,
            });
            if (!uploadResponse.ok) throw new PTPException("File could not be uploaded at this time2");

            const refreshResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-refresh-program-file`, {
                body: JSON.stringify({
                    filename: file.name,
                    program: programName,
                }),
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
            });
            console.log(refreshResponse);
            if (!refreshResponse.ok) throw new PTPException("File could not be uploaded at this time");

            displayConfirmationModal();
        } catch (err) {
            console.log(err);
            alert(err.name === "PTPException" ? err.message : "File could not be uploaded at this time4");
        } finally {
            inputFileRef.current.value = "";
            setFile(() => null);
            setUploadLoading(() => false);
        }
    };

    const handleTruePaid = async (e) => {
        const truePaidResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-refresh-true-paid-process-batch`, {
            // body: JSON.stringify({
            // 	filename: file.name,
            // 	program: programName,
            // }),
            method: "POST",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
        });
        if (!truePaidResponse.ok) throw new PTPException("FAILURE");
    };

    const handleFileChange = async (e) => {
        //setFiles(Array.from(e.target.files));
        const selectedFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };

    const uploadedFileNames = files.map((item) => item.name);

    //const missingFiles = Object.keys(requiredAxsomeFiles).filter((key) => !uploadedFileNames.some((fileName) => fileName.includes(requiredAxsomeFiles[key])));

    const displayConfirmationModal = () => {
        setConfirmationModalActive(() => true);

        setTimeout(() => {
            setConfirmationModalActive(() => false);
        }, 2500);
    };

    //Resets files back to empty whenever program selector is changed
    useEffect(() => {
        setFiles([]);
        setFile(null);
        if (inputFileRef.current) {
            inputFileRef.current.value = "";
        }
    }, [programName]);

    // return (
    //     <div className="centered-container">
    //         {uploadLoading === true && <LoadingModal text="Uploading file..." />}
    //         {confirmationModalActive && <ConfirmationModal text="File successfully refreshed"></ConfirmationModal>}
    //         <div className="program">
    //             {programName === "Auvelity" ? (
    //                 <div>
    //                     <form className={simpleFormStyles.simpleForm}>
    //                         <div className={simpleFormStyles.simpleFormInputContainer}>
    //                             <label className={simpleFormStyles.simpleFormLabel}>Files:</label>
    //                             <input
    //                                 className={simpleFormStyles.simpleFormFileInput}
    //                                 type="file"
    //                                 name="file"
    //                                 multiple
    //                                 accept=".txt,.csv,.xlsx"
    //                                 onChange={handleFileChange}
    //                                 ref={inputFileRef}
    //                             ></input>
    //                         </div>
    //                         <div style={{ paddingTop: "10px", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
    //                             <h2 style={{ color: "black" }}>Required Files: </h2>
    //                             {Object.entries(requiredAxsomeFiles).map(([key, keyword]) => (
    //                                 <h3
    //                                     key={key}
    //                                     style={{
    //                                         display: "flex",
    //                                         color: uploadedFileNames.some((fileName) => fileName.includes(keyword)) ? "white" : "white",
    //                                         paddingLeft: "10px",
    //                                         paddingBottom: "10px",
    //                                     }}
    //                                 >
    //                                     {keyword.toUpperCase()} FILE {uploadedFileNames.some((fileName) => fileName.includes(keyword)) ? "🟢" : "🔴"}
    //                                 </h3>
    //                             ))}
    //                         </div>
    //                         <div className={simpleFormStyles.simpleFormSubmitContainer}>
    //                             <button type="button" className="run" onClick={uploadAxsomeToS3}>
    //                                 Upload
    //                             </button>
    //                         </div>
    //                     </form>
    //                 </div>
    //             ) : (
    //                 <div>
    //                     <form className={simpleFormStyles.simpleForm}>
    //                         <div className={simpleFormStyles.simpleFormInputContainer}>
    //                             <label className={simpleFormStyles.simpleFormLabel}>File:</label>
    //                             <input
    //                                 className={simpleFormStyles.simpleFormFileInput}
    //                                 type="file"
    //                                 name="file"
    //                                 accept=".txt,.csv,.xlsx"
    //                                 onChange={(e) => {
    //                                     setFile(() => e.target.files[0]);
    //                                 }}
    //                                 ref={inputFileRef}
    //                             ></input>
    //                         </div>
    //                         <div className={simpleFormStyles.simpleFormSubmitContainer}>
    //                             <button type="button" className="run" onClick={uploadToS3}>
    //                                 Upload
    //                             </button>
    //                         </div>
    //                         {/* True Paid Refresh Button just in case automation doesn't work */}
    //                         {/* <Button variant="contained" onClick={handleTruePaid}>
    //                     True Paid Batch Button
    //                 </Button> */}
    //                     </form>
    //                 </div>
    //             )}
    //         </div>
    //     </div>
    // );
    return (
        <div className="centered-container">
            {uploadLoading === true && <LoadingModal text="Uploading file..." />}
            {confirmationModalActive && <ConfirmationModal text="File successfully refreshed"></ConfirmationModal>}
            <div className="program">
                {programName === "Auvelity" ? (
                    <div>
                        <form className={simpleFormStyles.simpleForm}>
                            <div className={simpleFormStyles.simpleFormInputContainer}>
                                <label className={simpleFormStyles.simpleFormLabel}>Files:</label>
                                <input
                                    className={simpleFormStyles.simpleFormFileInput}
                                    type="file"
                                    name="file"
                                    multiple
                                    accept=".txt,.csv,.xlsx"
                                    onChange={handleFileChange}
                                    ref={inputFileRef}
                                ></input>
                            </div>
                            <div style={{ paddingTop: "10px", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                                <h2 style={{ color: "black" }}>Required Files: </h2>
                                {Object.entries(requiredAxsomeFiles).map(([key, keyword]) => (
                                    <h3
                                        key={key}
                                        style={{
                                            display: "flex",
                                            color: uploadedFileNames.some((fileName) => fileName.includes(keyword)) ? "white" : "white",
                                            paddingLeft: "10px",
                                            paddingBottom: "10px",
                                        }}
                                    >
                                        {keyword.toUpperCase()} FILE {uploadedFileNames.some((fileName) => fileName.includes(keyword)) ? "🟢" : "🔴"}
                                    </h3>
                                ))}
                            </div>
                            <div className={simpleFormStyles.simpleFormSubmitContainer}>
                                <button type="button" className="run" onClick={uploadAxsomeToS3}>
                                    Upload
                                </button>
                            </div>
                        </form>
                    </div>
                ) : programName === "Xarelto" ? ( // Third scenario
                    <div>
                        <form className={simpleFormStyles.simpleForm}>
                            <div className={simpleFormStyles.simpleFormInputContainer}>
                                <label className={simpleFormStyles.simpleFormLabel}>Files:</label>
                                <input
                                    className={simpleFormStyles.simpleFormFileInput}
                                    type="file"
                                    name="file"
                                    multiple
                                    accept=".txt,.csv,.xlsx"
                                    onChange={handleFileChange}
                                    ref={inputFileRef}
                                ></input>
                            </div>
                            <div style={{ paddingTop: "10px", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                                <h2 style={{ color: "black" }}>Required Files: </h2>
                                {Object.entries(requiredXareltoFiles).map(([key, keyword]) => (
                                    <h3
                                        key={key}
                                        style={{
                                            display: "flex",
                                            color: uploadedFileNames.some((fileName) => fileName.includes(keyword)) ? "white" : "white",
                                            paddingLeft: "10px",
                                            paddingBottom: "10px",
                                        }}
                                    >
                                        {keyword.toUpperCase()} FILE {uploadedFileNames.some((fileName) => fileName.includes(keyword)) ? "🟢" : "🔴"}
                                    </h3>
                                ))}
                            </div>
                            <div className={simpleFormStyles.simpleFormSubmitContainer}>
                                <button type="button" className="run" onClick={uploadXareltoToS3}>
                                    Upload
                                </button>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div>
                        <form className={simpleFormStyles.simpleForm}>
                            <div className={simpleFormStyles.simpleFormInputContainer}>
                                <label className={simpleFormStyles.simpleFormLabel}>File:</label>
                                <input
                                    className={simpleFormStyles.simpleFormFileInput}
                                    type="file"
                                    name="file"
                                    accept=".txt,.csv,.xlsx"
                                    onChange={(e) => {
                                        setFile(() => e.target.files[0]);
                                    }}
                                    ref={inputFileRef}
                                ></input>
                            </div>
                            <div className={simpleFormStyles.simpleFormSubmitContainer}>
                                <button type="button" className="run" onClick={uploadToS3}>
                                    Upload
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
}
